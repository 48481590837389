import React, {useState} from 'react'
import "../Styles/Desktop.css"
import { useNavigate, Link } from "react-router-dom";
import MusicPlayer from '../Pages/MusicPlayer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphonesSimple, faMusic, faHandcuffs } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faSquareFacebook, faGithub, faWikipediaW } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import Chat from './Chat';

export default function Desktop() {
  const navigate = useNavigate()

  const lexBase = "https://www.lexbase.se/personsok?Search%5Bfullname%5D=philip+jansson&Search%5Bpersonal_id%5D=19931027&Search%5Bcity%5D=&_=&s=4de659c27844f4290fc0efae4de143f0"
  const faceBook = "https://www.facebook.com/philip.jansson.121"
  const linkedIn = "https://www.linkedin.com/in/philip-jansson-6a6107143/"
  const github = "https://github.com/Gitphil93/"

  const cv = () => {
    navigate("/cv")
  }
  const snake = () => {
    navigate("/snake")
  }

  const musicPlayer = () => {
    navigate("/music-player")
  }

  const philipWiki = () => {
    navigate("/philip-wiki")
  }

  const appleet = () => {
    navigate("/appleet")
  }
  const contact = () => {
    navigate("/contact-me")
  }




  
  return (
    <div className="desktop-container">
{/*       <Chat /> */}
      <img src="/philip2.jpg" className="background-image" alt="background" />
      <div className="desktop-items">

      <div className="desktop-item" onClick={cv}>
      <div className="item-pic">
          <img src="/philip2.jpg" alt="Philip" className="item-pic"/>
          </div>
          <h1 className="item-text">CV</h1>
        </div>

        <div className="desktop-item" onClick={philipWiki}>
        <div className="item-pic">
        <FontAwesomeIcon icon={faWikipediaW} id ="wikipedia-icon" />
          </div>
          <h1 className="item-text">Philip Wiki</h1>
        </div>


     {/*    <div className="desktop-item" onClick={snake}>
          <img src="/snake.svg" alt="Philip" className="item-pic"/>
          <h1 className="item-text">Snake</h1>
        </div> */}

        <div className="desktop-item" onClick={musicPlayer}>
        <div className="item-pic">
          <FontAwesomeIcon icon={faMusic} id="music-icon"/>
          </div>
          <h1 className="item-text">Music</h1>
        </div>

        <Link className="desktop-item" to={linkedIn} target="_blank" rel="noopener noreferrer" >
        <div className="item-pic">
          <FontAwesomeIcon icon={faLinkedin} id="linkedin-icon" fill="white" style={{height: "4.5rem"}} />
          </div>
          <h1 className="item-text">LinkedIn</h1>
        </Link>

        <Link className="desktop-item" to={faceBook} target="_blank" rel="noopener noreferrer" >
        <div className="item-pic">
        <FontAwesomeIcon icon={faSquareFacebook} id="facebook-icon" style={{height: "4.5rem"}}/>
        </div>
          <h1 className="item-text">Facebook</h1>
        </Link>

        <Link className="desktop-item" to={github} target="_blank" rel="noopener noreferrer" >
        <div className="item-pic">
        <FontAwesomeIcon icon={faGithub} id="github-icon"/>
        </div>
          <h1 className="item-text">Github</h1>
        </Link>



        <Link className="desktop-item" to={lexBase} target="_blank" rel="noopener noreferrer" >
        <div className="item-pic">
{/*         <FontAwesomeIcon  icon={faHandcuffs} id="lexbase-icon" /> */}
            <img src="/rob.svg" alt="rob.svg" id="lexbase-icon" />
         </div>
          <h1 className="item-text">Lexbase</h1>
        </Link>
        
        <div className="desktop-item" onClick={contact}>
          <div className="item-pic">
        <FontAwesomeIcon  icon={faEnvelope} id="contact-icon" />
        </div>
          <h1 className="item-text">Contact</h1>
        </div>

        </div>


        <div className="main-logo">
    {/*  */}
        </div>

    </div>
  )
}
