import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from './Pages/Main';
import Cv from "./Pages/Cv";
import ContactMe from './Pages/ContactMe';
import Snake from './Pages/Snake';
import MusicPlayer from './Pages/MusicPlayer';
import AboutMe from './Pages/AboutMe';
import Appleet from './Pages/Appleet';
import CookieConsent from './Components/CookieConsent';
import BootScreen from './Components/BootScreen';
import Admin from './Pages/Admin';
import Login from './Pages/Login';
import ProtectedRoute from './Components/ProtectedRoute';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import { useState, useEffect } from 'react';

function App() {
  const [isAuthenticated, setAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated') === 'true';
    setAuthenticated(storedAuth);
  }, []);

  useEffect(() => {
    const setVisitorCookie = async () => {
      try {
 
       
        
       await fetch('https://philipjanssondev-backend.vercel.app/visitor', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ip: null}),
          credentials: 'include', 
        });
          
    
      } catch (error) {
        console.error("Det gick inte att sätta cookien:", error);
      }
    };

    // Sätt cookien när appen laddas
    setVisitorCookie();
  }, []);

  return (
    <div className="App">
      <BootScreen />
      <CookieConsent />
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="cv" element={<Cv />} />
          <Route path="contact-me" element={<ContactMe />} />
          <Route path="music-player" element={<MusicPlayer />} />
          <Route path="philip-wiki" element={<AboutMe />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="login" element={<Login setAuthenticated={setAuthenticated} />} />
          <Route path="admin" element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <Admin />
            </ProtectedRoute>
          } />
        </Routes>
      </Router>
    </div>
  );
}


export default App;
