import React, {useRef, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import "../Styles/AboutMe.css";
import Header from '../Components/Header';
import Footer from "../Components/Footer"

export default function AboutMe() {
    const navigate = useNavigate();

    const contact = () => {
        navigate("/contact-me");
    }

    const sectionsRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
            }
          });
        });
    
    
    
        // Lägg till alla sektioner som ska observeras
        sectionsRef.current.forEach((section) => {
          if (section) observer.observe(section);
        });
    
        return () => {
          sectionsRef.current.forEach((section) => {
            if (section) observer.unobserve(section);
          });
        };
      }, []);
    return (
        <div className="about-me-wrapper">
            <Header />
            <section className="hero-section">
                <div className="hero-section-heading">
                    <h1 className="heading-name fade-in" ref={(el) => (sectionsRef.current[0] = el)}>Philip Jansson</h1>
                    <h1 className="heading-text fade-in" ref={(el) => (sectionsRef.current[1] = el)}>Your Future Employee</h1>
                </div>
            </section>

            <section className='about-section'>
                <div className="about-me-text">
                    <div className="fade-in" ref={(el) => (sectionsRef.current[2] = el)}>
                    <h2 className="title">Who am I?</h2>
                    <p>
                        My name is Philip Jansson. I'm 31 years old, and I live in Gothenburg with my girlfriend. My life revolves around three main passions: coding, music production, and exercising, which together form the foundation of my happiness.
                    </p>
                    <p>
                        Coding and music production, in particular, are not just hobbies for me—they are integral parts of my life. I produce synthwave music under the name Mondai, allowing me to dive deep into the vibrant and nostalgic sounds of the 80s. In addition to my music, I am also working on an app called Appleet, which is designed specifically for athletics coaches. This app provides a platform where coaches can add their athletes, create custom exercises, and plan detailed training sessions. Appleet is built to streamline the coaching process, making it easier to monitor performance, adjust training plans, and ultimately help athletes reach their full potential.
                    </p>
                    <p>
                        What fascinates me about coding and music production is how they both engage my mind in similar ways. Whether I'm away from the screen or the studio, these passions have a way of embedding themselves into my thoughts, urging me to explore new ideas and solutions. I truly love living with a project, whether it's a song or an app. That feeling of being completely immersed in creating something is what drives me and keeps me going.
                    </p>

                    <div className="fade-in" ref={(el) => (sectionsRef.current[3] = el)}>
                    <h2 className='title'>What do I want?</h2>
                    <p>
                        My goal is to develop innovative apps that address real-world problems. I am focused on creating meaningful solutions rather than just delivering generic websites. I’m particularly interested in projects that challenge me and help me grow as a developer, allowing me to explore new ideas and technologies while working on something that genuinely matters.
                    </p>
                    <p>
                        I am actively seeking a job where I can apply these passions and skills. I’m looking for a role that allows me to contribute to projects that make a difference, while also offering opportunities for personal and professional growth. Whether it’s in a dynamic startup environment or a well-established company, I want to be part of a team that shares my commitment to innovation and problem-solving.
                    </p>
                    </div>
                    </div>

                    <div className="fade-in" ref={(el) => (sectionsRef.current[4] = el)}>
                    <h2 className='title'>Where do I want to do this?</h2>
                    <p>
                        Location isn’t a big concern for me, whether it’s in Gothenburg, remotely, or somewhere else.
                    </p>
                    </div>
                    <div className="fade-in" ref={(el) => (sectionsRef.current[5] = el)}>
                    <h2 className='title'>My own projects</h2>
                    <p id="project-start-paragraph">
                        <span className="project-start">Appleet</span> is an app I'm building specifically for athletics coaches. It provides a platform that allows coaches to effectively manage and track their athletes' progress. With Appleet, coaches can add and organize their athletes, create custom exercises tailored to each athlete’s needs, and plan detailed training sessions. The app is built to streamline the coaching process, making it easier to monitor performance, adjust training plans, and ultimately help athletes reach their full potential. Whether you’re coaching a small group or an entire team, Appleet offers the tools needed to enhance your coaching efficiency and effectiveness.
                    </p>

                    <p>
                        <span className="project-start">SpyTunes</span> is an app that lets users see what others are listening to in real time and where they are located on a map. Using the Spotify API, Firebase, Node.js Express, and Mapbox for geolocation, Spytunes connects people through shared musical experiences.

While currently web-based, there are plans to port the app to React Native, enabling integration with the phone’s native media playback APIs. This opens the door to pulling playback data not just from Spotify, but from a wide range of streaming services, making Spytunes a versatile platform for music discovery and social interaction.
                    </p>

                    <p>
                        <span className="project-start">Candee</span> is an app designed to offer an online experience for purchasing loose candy. Users can browse different candy options, add them to their virtual candy bag, and have them delivered to their location. The app allows users to create custom candy mixes and choose from various types of candy, with the option to set up recurring orders for convenience.

The goal of Candee is to provide an easy way for users to purchase and enjoy their favorite candies without the need to visit a store.
                    </p>


                    {/* <p>Try it out <Link to="/appleet" id="link">here.</Link></p> */}
                    </div>

                    <div className="fade-in" ref={(el) => (sectionsRef.current[6] = el)}>
                    <h2 className='title'>Music Syncs</h2>
                    <p>
                        I've had the pleasure of creating music for various projects, including two Volvo commercials.
                    </p>

                    <div className="video-wrapper">
                        <div className="video-container fade-in" ref={(el) => (sectionsRef.current[7] = el)}>
                            <h3 className='title'>Be the Bear - Erupt</h3>
                            <iframe 
                                width="100%"  
                                height="315" 
                                src="https://www.youtube.com/embed/Kb-3G--DB7Y" 
                                title="Be The Bear - Erupt (Volvo Winter Story Commercial)" 
                                frameBorder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerPolicy="strict-origin-when-cross-origin" 
                                allowFullScreen
                            ></iframe>
                        </div>

                        </div>

                        <div className="video-container fade-in" ref={(el) => (sectionsRef.current[8] = el)}>
                            <h3 className='title'>Be the Bear - Malibu Lover</h3>
                            <iframe 
                                width="100%" 
                                height="315"
                                src="https://www.youtube.com/embed/0w9_n3AvOi0"
                                title="The New Volvo V40 R-Design"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>


                        <div className="reach-out fade-in" ref={(el) => (sectionsRef.current[9] = el)}>
                    <h2 className='title'>Are you reaching out?</h2>
                    <p>Does it sound like I could be your future colleague, or are you in need of assistance with development? Or maybe music? Feel free to reach out to me!</p>

                    <button className="reach-out-button" onClick={contact}>I'm reaching out!</button>
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    );
}
