import React, { useEffect, useState, useRef } from "react";
import Header from "../Components/Header";
import "../Styles/Cv.css";
import Footer from "../Components/Footer";
import { useNavigate } from "react-router-dom";
import BackArrow from "../Components/BackArrow";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faAddressCard,faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import { faStripe, faGithub, faDocker, faHtml5, faSquareJs, faNode, faCss3, faReact, faVuejs, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
/* import {faStripe} from '@fortawesome/fontawesome-free/css/brands.css'; */


export default function Cv() {
  const navigate = useNavigate();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const [showContent, setShowContent] = useState(false)
  const [password, setPassword] =useState("")
  const [error, setError] = useState("");



  const validatePassword = () => {
    if (password === "ImCurious2025") {
      setShowContent(true);
      setError("");
      localStorage.setItem("password", "true")
    } else {
      setError("The password is incorrect.");
    }
  };

  const sectionsRef = useRef([]);

  useEffect(() => {
     if (!showContent){
      return
  }
    const handleMouseMove = (event) => {
      const topColumn = document.querySelector(".top-column");
      const topColumnRect = topColumn.getBoundingClientRect();

      // Kolla om musen är utanför top-column
      if (
        !isMobile &&
        (event.clientY > topColumnRect.bottom ||
          event.clientX < topColumnRect.left ||
          event.clientX > topColumnRect.right)
      ) {
        setMousePosition({
          x: event.clientX,
          y: event.clientY,
        });
      }
    };

    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("resize", checkIsMobile);

    checkIsMobile(); // Initial check

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("resize", checkIsMobile);
    };
  }, [isMobile, showContent]);

  const contactMe = () => {
    navigate("/contact-me");
  };


  useEffect(() => {
    if (showContent){
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      });
  
  
  
      // Lägg till alla sektioner som ska observeras
      sectionsRef.current.forEach((section) => {
        if (section) observer.observe(section);
      });
  
      return () => {
        sectionsRef.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      };
    } 

  }, [showContent]);

  useEffect(() => {
    const hasPassword = localStorage.getItem("password")
    if (hasPassword){
      setShowContent(true)
    } 

  }, [])

  return (
    <>  
       <Header />
          {!showContent ? (
        <div className="password-container">
          <h2>Enter the password to view content</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button onClick={validatePassword}>Submit</button>
          {error && <p className="error-message">{error}<br></br><br></br>Call me at +46760-549046 or through <a href="/contact-me">this</a> form to get access to my CV :)</p>}
        </div>
      ) : (
     <div className="wrapper">
 
      <BackArrow /> 

      <div className="cv-container">
      <div className="top-column">

          <div className="cv-header fade-in" ref={(el) => (sectionsRef.current[0] = el)}>
            <h1>Philip Jansson</h1>
            <h2>Web Developer</h2>
          </div>

 
          <div className="contacts fade-in" ref={(el) => (sectionsRef.current[1] = el)}>
            <span className="contact-item">
            <FontAwesomeIcon className="contact-icon" icon={faPhone}/>
          <h2>+46760-549046</h2>
          </span>
          <span className="contact-item">
          <FontAwesomeIcon icon={faPaperPlane} className="contact-icon" />
          <h2>philip@philipjansson.se</h2>
          </span>
          <span className="contact-item">
          <FontAwesomeIcon icon={faAddressCard} className="contact-icon" />
            <h2>Koopmansgatan 11A, Gothenburg </h2>
            </span>

          </div>
        </div>

        <div className="bottom-column">
          <section className="about-me fade-in" ref={(el) => (sectionsRef.current[2] = el)}>
            <div className="heading">
              <h1>Profile</h1>
            </div>

            <div className="cv-description">
              <p>
              Recently coming from Staria, where I worked as a NetSuite consultant, developing and implementing NetSuite for customers, and customizing it to meet their financial and logistical needs. I primarily used TypeScript, SuiteScript, and SuiteQL. Although I enjoyed the role, my probationary employment was unfortunately terminated due to a lack of incoming projects. I have worked in various areas throughout my career, which has provided me with extensive experience. I am always eager to dive into new challenges where needed and learn quickly. I'm enthusiastic about applying my skills to meaningful work and contributing to a team that values innovation and growth.
              </p>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[3] = el)}>
            <div className="heading">
              <h1>Experience</h1>
            </div>

            <div className="experience-item">
              <div className="item-heading">
                <h1>Systems Developer, Staria AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>August 2023 - January 2024</p>
              </div>

              <div className="item-description">
                <p>
                Customized and developed NetSuite to meet customers' financial and logistical needs using JavaScript, SuiteScript, and suiteQL. Enjoyed the role very much but unfortunately, my probationary employment was terminated due to a low volume of available projects.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[4] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Frontend Developer, Input Interiör AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>November 2022 - May 2023</p>
              </div>

              <div className="item-description">
                <p>
                20 weeks of internship where I developed a product configurator and an app for booking meetings and conference rooms.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[5] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Relining Technician, Tubus System AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>October 2019 - August 2021</p>
              </div>

              <div className="item-description">
                <p>
                Worked as a traveling relining technician, responsible for relining main pipes and foundation plates in various buildings.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[6] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Account Manager, Pinscher Media AB, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>February 2018 - April 2019</p>
              </div>

              <div className="item-description">
                <p>
                Worked as a B2B salesperson, selling advertisements in tabloids and online newspapers.
                </p>
              </div>
            </div>
          </section>

          <section className="experience fade-in" ref={(el) => (sectionsRef.current[7] = el)}>
            <div className="experience-item">
              <div className="item-heading">
                <h1>Field Sales Representative/Store Staff, Ad Bildelar, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>August 2013 - October 2016</p>
              </div>

              <div className="item-description">
                <p>
                Sold the Ad Bilverkstad concept to workshops and spare parts to both workshops and consumers.
                </p>
              </div>
            </div>
          </section>

          <section className="education fade-in" ref={(el) => (sectionsRef.current[8] = el)}>
            <div className="experience">
              <h1 className="education-heading">Education</h1>
            </div>

            <div className="experience-item">
              <div className="item-heading">
                <h1>IT-högskolan, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>September 2024 - December 2024</p>
              </div>

              <div className="item-description">
                <p>
                I have completed a course in Microsoft Azure, covering topics such as Azure Functions and serverless code, Azure Active Directory, security features, cost optimization, and automated deployment. The course included PaaS, IaaS, and SaaS models, virtual machine management, web and mobile services implementation, and foundational knowledge of Azure Kubernetes Service (AKS).<br></br><br></br>
Additionally, I learned about DevOps principles, CI/CD pipelines, and version control with Git. I am now equipped to design, develop, and manage cloud functionality with Azure, implement secure and cost-efficient cloud solutions, and automate build and deployment processes using Azure DevOps.<br></br><br></br>During this journey, I have also taken the opportunity to dive into C#/.NET to familiarize myself with the language and framework, setting a strong foundation for further development and integration with Azure services.
                </p>
              </div>
            </div>

            <div className="experience-item">
              <div className="item-heading">
                <h1>Jensen Vocational Education, Gothenburg</h1>
              </div>

              <div className="duration">
                <p>August 2021 - June 2023</p>
              </div>

              <div className="item-description">
                <p>
                I have completed a Frontend Development program with a specialization in web security. The course covered HTML, CSS, JavaScript, TypeScript, Node.js, SQL, React, and Vue.js, along with Agile development methodologies such as Scrum and Kanban.<br></br><br></br>It also included advanced topics like secure internet communication, OWASP Top 10, and modern CI/CD practices.

I gained hands-on experience in building secure, scalable web applications and developing solutions with a strong focus on user experience and performance. The program emphasized best practices for secure coding and addressing vulnerabilities in web development environments.
                </p>
              </div>
            </div>


          </section>

          <section className="skills fade-in" ref={(el) => (sectionsRef.current[9] = el)}>
            <div className="experience">
              <h1>Skills</h1>
            </div>

            <div className="skills-items">
              <div className="skills-item">
                <h2>HTML</h2>
                <FontAwesomeIcon className="skills-icon"  icon={faHtml5} style={{color: "red"}}/>
              </div>

              <div className="skills-item">
                <h2>CSS</h2>
                <FontAwesomeIcon className="skills-icon"  icon={faCss3} style={{color: "blue"}}/>
              </div>

              <div className="skills-item">
                <h2>Javascript</h2>
                <FontAwesomeIcon className="skills-icon"  icon={faSquareJs} style={{color: "black"}}/>
              </div>

              <div className="skills-item">
                <h2>Typescript</h2>
                <img src="/ts.png" className="skills-icon" alt="ts"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/React-Logo.png" alt="React"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/Vuejs.png" alt="Vuejs"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/node.png" alt="Node"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/nodeexpress.png" alt="Express"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/Firebase_Logo.svg" alt="Firebase"/>
              </div>

              <div className="skills-item">
                <img className="skills-img"  src="/MongoDbLogo.png" alt="MongoDB"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/azure.svg" alt="Azure"/>
              </div>

              <div className="skills-item">
                <img className="skills-img" src="/sql-logo.png" alt="sql"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/sqlworkbench.png" alt="SQL Workbench"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/docker.png" alt="Docker"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/github.png" alt="Github"/>
              </div>
              
              <div className="skills-item">
                {/* <h2>NetSuite</h2> */}
                <img className="skills-img"  src="/netsuite.png" alt="Netsuite"/>
              </div>

              <div className="skills-item">
              <img className="skills-img"  src="/owasp.png" alt="OWASP"/>
              </div>
              <div className="skills-item">
              <img className="skills-img"  src="/agile.png" alt="Agile"/>
              </div>
              <div className="skills-item">
              <img className="skills-img"  src="/stripe.png" alt="Stripe"/>
              </div>
            </div>

            {isMobile && (
              <button className="fixed-button" onClick={contactMe}>
                Contact me!
              </button>
            )}
          </section>
        </div>
      </div>

      <Footer />

      {!isMobile && (
        <button
          className="follow-mouse-button"
          style={{ top: mousePosition.y, left: mousePosition.x }}
          onClick={contactMe}
        >
          Contact me!
        </button>
      )}
    </div>
      )}
    </>
      
  );
}
