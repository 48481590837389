import {react, useState, useEffect } from 'react'
import "../Styles/Header.css"
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi } from '@fortawesome/free-solid-svg-icons'; // Importera de ikoner du vill använda


export default function Header() {
    const navigate = useNavigate()

    const getCurrentTimeAndDay = () => {
        const currentTime = new Date();
        const hour = currentTime.getHours().toString().padStart(2, "0");
        const min = currentTime.getMinutes().toString().padStart(2, "0");
        const formattedTime = hour + ":" + min;

        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const today = currentTime.getDay();
        const formattedDay = days[today];

        return { formattedTime, formattedDay };
    };


    useEffect(() => {
        const interval = setInterval(() => {
            const currentTime = new Date();
            const hour = currentTime.getHours().toString().padStart(2, "0");
            const min = currentTime.getMinutes().toString().padStart(2, "0");
            const formattedTime = hour + ":" + min;
            
            const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const today = currentTime.getDay();
            const formattedDay = days[today];
            
            setTime(formattedTime);
            setDay(formattedDay);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const { formattedTime, formattedDay } = getCurrentTimeAndDay();
    const [time, setTime] = useState(formattedTime);
    const [day, setDay] = useState(formattedDay);


    const home = () => {
        navigate("/")
    }

  return (
    <div className="header-container">
        <h1 onClick={home}>philipdevOS</h1>

        <div className="language-select">
   
        </div>

    <div className="time-date">
    <img src="./englandflag.svg" alt="flag" className="flag"/>
        <h2><FontAwesomeIcon icon={faWifi} /></h2>
        <h2>{day}</h2>
        <h2>{time}</h2>
        </div>
    </div>
  )
}
