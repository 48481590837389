const translations = {
    en: {
      welcome: "Welcome to my portfolio!",
      language_toggle: "Switch Language",
      projects: "My Projects",
      contact: "Contact Me"
    },
    sv: {
      welcome: "Välkommen till mitt portfolio!",
      language_toggle: "Byt språk",
      projects: "Mina projekt",
      contact: "Kontakta mig"
    }
  };
  
  export default translations;
  