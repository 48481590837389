import React from 'react'
import "../Styles/PrivacyPolicy.css"
import Header from '../Components/Header'
import Footer from '../Components/Footer'

export default function PrivacyPolicy() {
  return (
    <div className="privacy-policy-wrapper">
        <Header />
        <div className="privacy-policy-intro">
            <h1>Privacy Policy</h1>
            <p>I'm committed to protecting your privacy. This Privacy Policy explains how I collect, use, and disclose your personal information when you visit and interact with this website philipjansson.se, (the "Site"). By using my Site, you agree to the collection and use of information in accordance with this policy.</p>
        </div>
     <div className="privacy-policy-item">
         <h1>1. Information I Collect</h1>
         <p className="bold-p">Personal Information:</p>
         <p>Name, Email address, Phone number and other personal information you voluntarily provide in forms, surveys, or emails.</p>
         <p className="bold-p">Non-Personal Information:</p>
         <p>Cookies and tracking technologies (e.g. Google Analytics)</p>
     </div>

     <div className="privacy-policy-item">
         <h1>2. How I Use Your Information</h1>
         <p>To provide and maintain our services, To communicate with you (e.g., for marketing purposes), To personalize your experience on the Site, To analyze and improve the Site’s functionality and performance</p>
     </div>

     <div className="privacy-policy-item">
         <h1>3. Cookies and Tracking Technologies</h1>
         <p>I use cookies and similar tracking technologies to enhance your experience on my Site. Cookies are small files stored on your device that help me recognize your preferences and analyze site traffic. You can choose to disable cookies through your browser settings, but this may affect your ability to use certain features of this Site.</p>
     </div>

     <div className="privacy-policy-item">
         <h1>4. Data Sharing and Disclosure</h1>
         <p>We do not sell or rent your personal information to third parties. However, we may share your data in the following situations:</p>
         <p>Service Providers: I may share your information with third-party companies that help us provide and manage the Site (e.g., hosting services, email providers, analytics tools)</p>
         <p>Legal Requirements: We may disclose your information if required to do so by law or in response to a valid request by a legal authority (e.g., a court or government agency).</p>
         <p></p>
     </div>

     <div className="privacy-policy-item">
         <h1>5. Data Security</h1>
         <p>I take reasonable measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>
     </div>

     <div className="privacy-policy-item">
         <h1>6. Your Rights</h1>
         <p className="bold-p">Depending on your location and applicable laws, you may have the right to:</p>
         <p>Access, update, or delete your personal information.</p>
         <p>Withdraw consent for processing your data.</p>
         <p>Object to the processing of your data for certain purposes.</p>
         <p>Request that I restrict the processing of your data.</p>
         <p>If you want to exercise any of the above rights, please email my privacy team at philip@philipjansson.se</p>
     </div>

     <div className="privacy-policy-item">
         <h1>7. Third-Party Links</h1>
         <p>My Site may contain links to third-party websites. I'm not responsible for the privacy practices or content of these external sites. I encourage you to read their privacy policies before submitting any personal information.</p>
     </div>


     <div className="privacy-policy-item">
         <h1>8. Changes to This Privacy Policy</h1>
         <p>I may update this Privacy Policy from time to time. Any changes will be posted on this page, and the updated policy will be effective immediately upon posting. I encourage you to review this policy periodically for any updates.</p>
     </div>
     <Footer/>
    </div>
  )
}
